import React from 'react'
import { connect } from "react-redux";

import logo from '../../assets/images/logo.png';
import { getisGeneratingDocument } from '../../store/mode';

import './LoaderDownload.css';

const LoaderDownload = (props) => {
  return (
    <>
      {
        props.isGeneratingDocument && (
          <div className='main'>
            <div className='logo-container'>
              <img src={logo} className="img-fluid rotate-vert-center" alt="logo" />
            </div>
            <p className='scale-up-center'>Generando documento</p>
          </div>
        )
      }
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    isGeneratingDocument: getisGeneratingDocument(state)
  };
};

export default connect(mapStateToProps, null)(LoaderDownload);
